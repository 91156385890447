import { graphql, useStaticQuery } from "gatsby"

import React from "react"
import { Col, Row } from "react-bootstrap"
import { DefaultHeader } from "../../../components/elements"
import { PageCenter } from "../../../components/elements/wrappers"
import { BecomeMemberForm } from "../../../components/forms"

import { Page } from "../../../components/elements"

export default function BecomeAmember() {
    const { allFile } = useStaticQuery(
        graphql`
            {
                allFile(
                    filter: {
                        relativePath: { regex: "/ca_list/" }
                        extension: { regex: "/jpg|png/" }
                    }
                    sort: { fields: base, order: ASC }
                ) {
                    edges {
                        node {
                            base
                            childImageSharp {
                                gatsbyImageData(
                                    height: 70
                                    quality: 80
                                    placeholder: NONE
                                    formats: [AUTO, WEBP, AVIF]
                                )
                            }
                        }
                    }
                }
            }
        `,
    )
    return (
        <Page
            title="Become a Member"
            description="EGI scientists collaborate with colleagues across academic, industry and government organizations globally to discover and improve scientific innovation and applied technologies."
        >
            <PageCenter bg="white">
                <DefaultHeader
                    title="Reach out for more information on becoming a member."
                    type="small"
                />
                <Row className="border-bottom pb-5">
                    <Col style={{ justifyContent: "center" }}>
                        <BecomeMemberForm />
                    </Col>
                </Row>

                <div className="EGI_Request_to_Contact_page">
                <h4>
                <br />
                Do you have a question, inquiry or feedback for us? Fill out the form on the <a href="https://egi.utah.edu/about/contact">Contact & Map page</a> and we will respond as soon as possible.
                </h4>
                </div>
                
            </PageCenter>
        </Page>
    )
}
